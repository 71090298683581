<template>
  <div>
    <add-project :project-data="projectData"></add-project>
  </div>
</template>

<script>
import AddProject from './AddProject.vue';

export default {
  components: {
    AddProject,
  },

  props: {
  projectData: {
    type: Object,
    default: function () {
      return {
        name: null,
        client: null,
        startDate: null,
        team: null,
        color: null,
        taskList: [],
      };
    },
  },
},


  data() {
    return {};
  },
};
</script>
